@font-face {
  font-family: "poppins";
  src: url("../fonts/Poppins/Poppins-Black.ttf") format("truetype");
  src: url("../fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
  src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  src: url("../fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  src: url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
  src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  /* Add additional src lines for different font file formats if available */
}
@font-face {
  font-family: "cairo";
  src: url("../fonts/Cairo/Cairo-VariableFont_slnt\,wght.ttf")
    format("truetype");

  /* Add additional src lines for different font file formats if available */
}
body {
  font-family: "poppins", "cairo";
}
.my-button {
  font-family: "poppins", "cairo" !important;
}
.my-button:hover {
  background: linear-gradient(to right, #c57600, #e27c00) !important;
}

.ant-table-cell,
.ant-modal-title,
.ant-form-item-explain-error > div,
.ant-select-selection-search-input::placeholder,
.ant-input-number-input::placeholder,
.ant-select-selection-placeholder,
#basic_times_0_formTo::placeholder,
.ant-picker-input > input::placeholder,
.our-font {
  font-family: "poppins", "cairo" !important;
}
